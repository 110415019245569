<template>
  <div class="sub_cont mypage">
    <div class="cont">
      <h1>마이페이지</h1>
      <div class="tab">
        <ul>
          <li @click="selectMode = constant.mode.INFO"
              :class="constant.mode.INFO === selectMode ? 'on' : ''">개인정보
          </li>
          <li @click="selectMode = constant.mode.CASH"
              :class="constant.mode.CASH === selectMode ? 'on' : ''">이용요금
          </li>
        </ul>
      </div>
        <component :is="selectMode === constant.mode.INFO ? 'Info' :
        (selectMode === constant.mode.CASH ? 'Cash' : '')"></component>
    </div>
  </div>
</template>


<script>
import { WOW } from 'wowjs'
import Info from './Info.vue'
import Cash from './Cash.vue'

export default {
  name: 'Mypage',
  components :{
    Info,
    Cash
  },
  mounted () {
    new WOW().init()
  },
  methods: {
  },
  data () {
    const constant = {
      mode: {
        INFO: '01',
        CASH: '02',
        INFO_EDIT: '03',
      }
    }
    return {
      constant,
      selectMode: constant.mode.INFO,
    }
  }
}
</script>

<style scoped>
</style>
