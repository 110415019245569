<template>
  <div>
    <h2>기본 정보</h2>
    <table class="table_basic pc" border="0" cellspacing="0" cellpadding="0">
      <colgroup>
        <col width="20%" />
        <col width="30%" />
        <col width="20%" />
        <col width="30%" />      
      </colgroup>
      <thead>
      </thead>
      <tbody>
      <tr>
        <th>회사명</th>
        <td>{{ userInfo.cmpyNm }}</td>
        <th>성명</th>
        <td>{{ userInfo.userNm }}</td>
      </tr>
      <tr>
        <th>휴대폰번호</th>
        <td v-if="vIsEdit">
          <input ref="spTelNoRef"
                 placeholder=" - 를 제외한 11자리"
                 maxlength="11"
                 v-model.trim="userInfo.spTelNo.replace(/[^0-9]/g, '')"
                 @input="() => {userInfo.spTelNo = userInfo.spTelNo.replace(/[^0-9]/g, '')}"/>
        </td>
        <td v-else>{{ userInfo.spTelNo }}</td>
        <th>ID</th>
        <td>{{ userInfo.userId }}</td>
      </tr>
      <tr v-if="vIsEdit">
        <th>비밀번호</th>
        <td>
          <input
              type="password"
              class="k-textbox"
              :style="'width: 100%;'"
              :placeholder="'비밀번호를 입력하세요.'"
              v-model.trim="userPwd"/>
          <div v-show="pwdValidator" class="msg_red">비밀번호는 8자 이상의 문자,숫자,특문으로 작성해주세요.</div>
        </td>
        <th>비밀번호재입력</th>
        <td>

          <input
              type="password"
              class="k-textbox"
              :style="'width: 100%;'"
              :placeholder="'비밀번호를 재입력하세요.'"
              v-model.trim="userPwdConfirm"/>
          <div v-show="userPwdConfirm ? userPwd !== userPwdConfirm : false" class="msg_red">새 비밀번호와 맞지 않습니다. 기재한 비밀번호를 확인해주세요.</div>
        </td>
      </tr>
      </tbody>
    </table>
    <table class="table_basic mobile" border="0" cellspacing="0" cellpadding="0">
      <colgroup>
        <col width="30%"/>
        <col width="*"/>
      </colgroup>
      <thead>
      </thead>
      <tbody>
      <tr>
        <th>회사원</th>
        <td>{{ userInfo.cmpyNm }}</td>
      </tr>
      <tr>
        <th>성명</th>
        <td>{{ userInfo.userNm }}</td>
      </tr>
      <tr>
        <th>휴대폰번호</th>
        <td>{{ userInfo.spTelNo }}</td>
      </tr>
      <tr>
        <th>ID</th>
        <td>{{ userInfo.userId }}</td>
      </tr>
      </tbody>
    </table>
    <h2>개인 권한 정보</h2>
    <table class="table_basic" border="0" cellspacing="0" cellpadding="0">
      <colgroup>
        <col width="30%"/>
        <col width="*"/>
      </colgroup>
      <thead>
      </thead>
      <tbody>
      <tr>
        <th>관리자 여부</th>
        <td>{{ userInfo.adminYn }}</td>
      </tr>
      <tr>
        <th>이용약관 및 개인정보활용 동의 일시</th>
        <td class="txt_left">
          <span class="mgr10">{{ userInfo.psiAgrDt ? 'Y' : 'N' }}</span>
          {{ userInfo.psiAgrDt }}
        </td>
      </tr>
      <tr>
        <th>마케팅 동의 일시</th>
        <td v-if="!vIsEdit" class="txt_left">
          <span class="mgr10">{{ !!userInfo.mktAgrDt ? 'Y' : 'N' }} </span>{{ userInfo.mktAgrDt }}
        </td>
        <td v-else class="txt_left">
          <div class="maketing">
              <label for="mktY">Y <input type="radio" id="mktY" v-model="mktAgrDt" value="true"></label>
              <label for="mktN">N <input type="radio" id="mktN" v-model="mktAgrDt" value="false"></label>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <div v-if="!vIsEdit" @click="vIsEdit = true" class="box_btn">
      <div class="btn orange">수정</div>
    </div>
    <div v-if="vIsEdit" class="box_btn">
      <div @click="e=>{
        vIsEdit=false
        getUserInfo()
      }" class="btn">취소</div>
      <div @click="onClickSave" class="btn orange">저장</div>
    </div>
    <popup-for-landing
        ref="popupRef">
    </popup-for-landing>
  </div>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import PopupForLanding from '@/landing/components/PopupForLanding'

export default {
  name: 'Info',
  components: { PopupForLanding },
  props :{
    isEdit : {
      type : Boolean,
      default : false,
    },
  },
  computed : {
    pwdValidator : function (){
      if(!this.userPwd) return false
      const matched = /(?=.*\d{1,50})(?=.*[~`!@#$%\^&*()-+=]{1,50})(?=.*[a-zA-Z]{1,50}).{8,50}$/.test(this.userPwd)
      return !matched
    },
  },
  created () {
    const vm = this
    vm.getUserInfo()
  },
  methods : {
    getUserInfo : function (){
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/user/info`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.userInfo = response.data.resultData
              vm.mktAgrDt = !!vm.userInfo.mktAgrDt
            } else {
              vm.$refs.popupRef.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    onClickSave : function() {
      const vm = this
      // kendo.confirm(`저장 하시겠습니까?`).done(function () {
        vm.saveUserInfo()
      // })
    },
    saveUserInfo : function (){
      const vm = this
      let param = {
        userPwd : vm.userPwd,
        spTelNo : vm.userInfo.spTelNo,
        mktAgrDt : vm.mktAgrDt
      }
      ApiUtil.put(`${ApiConfig.efsDomain}/efs/user/info`, param)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.vIsEdit = false
              vm.getUserInfo()
              vm.$refs.popupRef.alert('입력하신 내용으로 수정되었습니다.')
            } else {
              vm.$refs.popupRef.alert(response.data.resultStatus.messageText)
            }
          },
      )
    }
  },
  data () {
    return {
      vIsEdit : false,
      userInfo : {
        mktAgrDt : null,
      },
      mktAgrDt : null,
      spTelNo : '',
      mktEmailAgrDt : null,
      mktSmsAgrDt : null,
      userPwd : null,
      userPwdConfirm : null,
    }
  },
}
</script>

<style scoped>

</style>
