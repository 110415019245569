<template>
  <div>
    <h2>결제내역</h2>
    <table class="table_basic pc" border="0" cellspacing="0" cellpadding="0">
      <colgroup>
        <col width="15%"/>
        <col width="*"/>
        <col width="15%"/>
        <col width="*"/>
        <col width="*"/>
        <col width="*"/>
        <col width="*"/>
      </colgroup>
      <thead>
      </thead>
      <tbody>
      <tr>
        <th>결제 일시</th>
        <th>이용 내역</th>
        <th>거래 방법</th>
        <th>거래 금액</th>
        <th>거래 상태</th>
        <th>결제자</th>
        <th>영수증</th>
      </tr>
      <tr v-for="item in payList">
        <td class="txt_c">{{ item.payDt }}</td>
        <td class="txt_c"><span class="txt_link"
                                @click="getPaymentInfo(item.billNo)">EFS Sass</span></td>
        <td class="txt_c">{{ item.payTypeNm }}</td>
        <td class="txt_c">{{ item.totAmount | comma}}원</td>
        <td class="txt_c">{{ item.payStatusNm }}</td>
        <td class="txt_c">{{ item.payer }}</td>
        <td class="txt_c">{{ item.billPubYn }}</td>
      </tr>
      <tr v-if="payList.length === 0">
        <td class="txt_c" colspan="7">결제된 내역이 없습니다.</td>
      </tr>
      </tbody>
    </table>
    <table class="table_basic mobile mgb10" border="0" cellspacing="0" cellpadding="0"v-for="item in payList">
      <colgroup>
        <col width="30%"/>
        <col width="*"/>
      </colgroup>
      <thead>
      </thead>
      <tbody>
      <tr>
        <th>결제 일시</th>
        <td class="txt_c"><span >{{ item.payDt }}</span></td>
      </tr>
      <tr>
        <th>이용 내역</th>
        <td class="txt_c"><span  class="txt_link"
                                @click="getPaymentInfo(item.billNo)">EFS Sass</span></td>        
      </tr>
      <tr>
        <th>거래 방법</th>
        <td class="txt_c"><span >{{ item.payTypeNm }}</span></td>
      </tr>
      <tr>
        <th>거래 금액</th>
        <td class="txt_c"><span >{{ item.totAmount | comma}}원</span></td>
      </tr>
      <tr>
        <th>거래 상태</th>
        <td class="txt_c"><span >{{ item.payStatusNm }}</span></td>
      </tr>
      <tr>
        <th>결제자</th>
        <td class="txt_c"><span >{{ item.payer }}</span></td>
      </tr>
        <th>영수증</th>
        <td class="txt_c"><span >{{ item.billPubYn }}</span></td>
      </tr>
      <tr v-if="payList.length === 0">
        <td class="txt_c" colspan="2">결제된 내역이 없습니다.</td>
      </tr>
      </tbody>
    </table>
    <div class="box_btn">
      <div @click="$router.push({name : 'Landing', params : {num : 8}})" class="btn orange">서비스 요금 문의</div>
    </div>
    <popup-for-landing
        ref="popupRef">
    </popup-for-landing>
  </div>
</template>

<script>
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import PopupForLanding from '@/landing/components/PopupForLanding'
export default {
  name: 'Cash',
  components: { PopupForLanding },
  computed : {
    loginUserData: function () {
      return this.$store.getters['loginUserData']
    },
  },
  created () {
    const vm = this
    vm.getPayment()
  },
  methods : {
    getPayment : function (){
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/payment`, {
        cmpyCd : vm.loginUserData.cmpyCd
      })
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.payList = response.data.resultData
            } else {
              vm.$refs.popupRef.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    getPaymentInfo : function (bilNo){
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/payment/${bilNo}`, {
        cmpyCd : vm.loginUserData.cmpyCd
      })
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.payInfo = response.data.resultData
              let totalAmount = 0
              let html = `
<table class="table_basic" border="0" cellspacing="0" cellpadding="0">
  <colgroup><col width="*" /><col width="*" /><col width="*" /></colgroup>
  <tbody>
  <tr><th>구분</th><th>이용 건수</th><th>금액</th></tr>`
              vm.payInfo.forEach((item,i)=>{
                let htmlTd = `<tr><td>${item.svcDtlNm}</td><td>${EtnersCommonUtil.numberWithCommas(item.unitCnt)}${item.svcDtlBillCdNm}</td><td>${EtnersCommonUtil.numberWithCommas(item.finalAmount)}원</td></tr>`
                html += htmlTd
                totalAmount += item.finalAmount
              })
              html += `<tr><td class="txt_c" colspan="2">합계</td><td class="txt_c">${EtnersCommonUtil.numberWithCommas(totalAmount)}원</td></tr>`
              html += `</tbody></table>`
              vm.$refs.popupRef.setTitle('상세 이용내역')
              vm.$refs.popupRef.info(html)
            } else {
              vm.$refs.popupRef.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
  },
  data (){
    return {
      payList : [],
      payInfo : {}
    }
  }
}
</script>

<style scoped>

</style>
